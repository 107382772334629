<template>
    <div class="content">
      <div class="container">
        <div class="row align-items-center">
          <div class="contents">
            <div id="content" style="background-color: transparent">
              
              <div class="col-12">
                <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <div class="col-md-12">PENCARIAN SURAT</div>
                    <br>
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <!-- <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Asal Surat</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="asal_surat"
                                />
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Nomor Surat</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="cari"
                                />
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Nomor Kendali</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="kendali"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row justify-content-md-center">
                          <label
                            class="text-right mt-2 col-md-3 col-sm-12"
                            style="font-size: 15px; font-weight: 700"
                            >Isi Ringkas</label
                          >
                          <div class="col-md-9 col-sm-12">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="ringkas"
                                />
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Submit</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInputs()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <br/>
                <!-- {{ suratList }} -->
                <br>
                <div class="row justify-content-md-center" v-if="suratList != 'Kosong'">
                  <table>
                    <tr>
                      <th colspan="3" style="text-align: center;">PEMERINTAH DAERAH</th>
                    </tr>
                    <tr>
                      <th colspan="3" style="text-align: center;">DAERAH ISTIMEWA YOGYAKARTA</th>
                    </tr>
                    <tr>
                      <th colspan="3"><hr></th>
                    </tr>
                    <tr>
                      <th>Asal Surat</th>
                      <th>:</th>
                      <th>{{ suratList.asalsurat }}</th>
                    </tr>
                    <tr>
                      <th>Nomor Surat</th>
                      <th>:</th>
                      <th>{{ suratList.nomorsurat }}</th>
                    </tr>
                    <tr>
                      <th>Tanggal Surat</th>
                      <th>:</th>
                      <th>{{ suratList.tanggal_surat }}</th>
                    </tr>
                    <tr>
                      <th>Perihal</th>
                      <th>:</th>
                      <th>{{ suratList.description }}</th>
                    </tr>
                    <tr>
                      <th>Lampiran</th>
                      <th>:</th>
                      <th>{{ suratList.pages }}</th>
                    </tr>
                    <tr>
                      <th>Nomor Kendali</th>
                      <th>:</th>
                      <th>{{ suratList.no_opd }}</th>
                    </tr>
                    <tr>
                      <th>Tanggal Diteruskan</th>
                      <th>:</th>
                      <th>{{ suratList.tanggal_surat }}</th>
                    </tr>
                    <tr>
                      <th>Dikirim ke</th>
                      <th>:</th>
                      <th>{{ suratList.testignya }}</th>
                    </tr>
                  </table>
                  
                </div>
              </div>  
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Authencrypt from "@/mixins/authencrypt";
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import axios from 'axios';

export default {
  components: {
    Input,
    RotateSquare5,
    Table
  },
  mixins: [Authencrypt],
  data() {
    return {
      axiosLoaded: false,
      suratList: [],
      formData: {
        nomor_surat: '',
        perihal: ''
      },
      nomor_surat: "",
      perihal: "",
      asal_surat: "",
      cari: "",
      kendali: "",
      ringkas: "",
      window: {
        width: 0,
        height: 0,
      },
      isMobile: false,
      passwordFieldType: "password",
    };
  },
  computed: {
    message() {
      return this.$store.state.auth.message;
    },
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.umum;
    },
  },
  methods: {

    changeDt() {
      // this.$children[0].$refs.table.reload();
      // console.log(this.cari);
      const state = {
        // loaded: false,
        // items: [],
        asal_surat: this.asal_surat,
        cari: this.cari,
        nomor_kendali: this.kendali,
        ringkas: this.ringkas,
      };
      // this.$store.commit("umum/STATE", state);
      // this.get(state);

      axios.post('auth/surat', state)
        .then(response => {
          // console.log('Form submitted successfully', response);
          loaded: true,
          this.suratList = response.data.data;
          console.log(this.suratList);
          return this.suratList;
          // const state = {
          //   loaded: true,
          //   // detail: response.data.data[0]
          //   suratList: response.data.data[0]
          // }
          // commit(types.STATE, state)
          // console.log(tes);
          // Reset the form after submission if needed
          // this.formData = {
          //   nomor_surat: '',
          //   perihal: ''
          // };
        })
        .catch(error => {
          console.error('Form submission error', error);
        });
      // console.log(state);
      // this.$store.commit("umum/STATE", state);
      // this.get(state);
    },
    get(val) {
      this.$store.dispatch("umum/getSurat", val);
    },
    resetInputs() {
      this.cari = "";
      this.asal_surat = "";
      this.kendali = "";
      this.ringkas = "";
      const state = {
        // loaded: false,
        // items: [],
        asal_surat: this.asal_surat,
        cari: this.cari,
        nomor_kendali: this.kendali,
        ringkas: this.ringkas,
      };
      // this.$store.commit("umum/STATE", state);
      // this.get(state);

      axios.post('auth/surat', state)
        .then(response => {
          // console.log('Form submitted successfully', response);
          loaded: true,
          this.suratList = response.data.data[0];
          console.log(this.suratList);
          return this.suratList;
          // const state = {
          //   loaded: true,
          //   // detail: response.data.data[0]
          //   suratList: response.data.data[0]
          // }
          // commit(types.STATE, state)
          // console.log(tes);
          // Reset the form after submission if needed
          // this.formData = {
          //   nomor_surat: '',
          //   perihal: ''
          // };
        })
        .catch(error => {
          console.error('Form submission error', error);
        });
      // const state = {
      //   loaded: false,
      //   items: [],
      //   from: this.from,
      //   search: this.search,
      //   cari: this.cari,
      //   asal_surat: this.asal_surat,
      //   kendali: this.kendali,
      //   ringkas: this.ringkas,
      // };
      // this.$store.commit("umum/STATE", state);
      // this.get(state);
      // this.$children[0].$refs.table.reload();
    },

    surat() {
      // Send form data to the server using Axios or your preferred HTTP library
      console.log(this.formData);
      // this.$store.dispatch("umum/submitSurat", this.formData).then((result) => {
      //           const state = {
      //             loaded: true,
      //           };
      //           this.$store.commit("umum/STATE", state);
      //           this.showModal({
      //             type: "information",
      //             nomor_surat: result.data.data.nomor_surat,
      //           });
      //           this.isVisible = true;
      //         })
      //         .catch(() => {
      //           const state = {
      //             loaded: true,
      //           };
      //           this.$store.commit("umum/STATE", state);
      //           this.isVisible = false;
      //         });
      // this.$store.dispatch("auth/surat", this.formData);
      // axios.post('auth/surat', this.formData)
      //   .then(response => {
      //     console.log('Form submitted successfully', response);
      //     // Reset the form after submission if needed
      //     this.formData = {
      //       nomor_surat: '',
      //       perihal: ''
      //     };
      //   })
      //   .catch(error => {
      //     console.error('Form submission error', error);
      //   });
    },
    // login() {
    //   const credentials = {
    //     username: this.username,
    //     password: this.password,
    //   };

    //   const objData = JSON.stringify(credentials);
    //   this.$validator.validateAll().then((success) => {
    //     if (success) {
    //       this.$store.dispatch("auth/login", objData);
    //     }
    //   });
    // },
    sisminkada: function () {
      window.open("http://103.131.105.2:2354/old2019/");
    },
    meeting: function () {
      window.open("http://103.131.105.2:2354/meeting-room/unit_list.php");
    },
    onEmail: function () {
      window.open("https://mail.jogjaprov.go.id/");
    },
    onSurat: function () {
      // window.open("https://paperless.jogjaprov.go.id:8000/umum");
      window.open("http://localhost:8080/umum");
    },
    penghargaan: function () {
      window.open("http://103.131.105.2:2354/penghargaan/penghargaan_list.php");
    },
    bend26a: function () {
      window.open("http://103.131.105.2:2354/bend26a/login.php");
    },
    pustaka: function () {
      window.open("http://slim.sisminkada.net/data/login.php");
    },
    webmail: function () {
      window.open("https://mail.jogjaprov.go.id/");
    },
    skp: function () {
      window.open("http://103.131.105.2:2354/skp/login.php");
    },
    // masuk() {
    //   const credentials = {
    //     nomor_surat: this.nomor_surat,
    //     perihal: this.perihal,
    //   };

    //   const objData = JSON.stringify(credentials);
    //   console.log(objData);
    //   this.$validator.validateAll().then((success) => {
    //     if (success) {
    //       this.$store.dispatch("/surat", objData);
    //     }
    //   });
    // },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    mobileCondition() {
      if (this.window.width <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    database_sisminkada() {
      window.open("http://103.131.105.2:2354/old2019/");
    },
    meeting_room() {
      window.open("http://103.131.105.2:2354/meeting-room/unit_list.php");
    },
    penghargaan() {
      window.open("http://103.131.105.2:2354/penghargaan/penghargaan_list.php");
    },
    bend26a() {
      window.open("http://103.131.105.2:2354/bend26a/login.php");
    },
    pustaka() {
      window.open("http://slim.sisminkada.net/data/login.php");
    },
    web_mail() {
      window.open("https://mail.jogjaprov.go.id/");
    },
    lampiran_skp() {
      window.open("http://103.131.105.2:2354/skp/login.php");
    },
  },
  mounted() {
    // Assuming you've imported Axios at the beginning of your script
    this.axiosLoaded = true;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.mobileCondition();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.bg-responsive {
  background-color: #e6e5e6;
  height: 100%;
}
</style>

